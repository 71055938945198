// import PropTypes from "prop-types"
import React from "react"
import Page from "../components/Page"

const About = () => {
  return (
    <Page>
      <h1>Hi. My name is Brad.</h1>
    </Page>
  )
}

About.propTypes = {
  // : PropTypes.string
}

export default About
